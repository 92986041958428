  /*  */
.main {
  width: 100%;
  height: 100%;
}

.main h3 {
  font-size: 16px;
}

.main p {
  font-size: 13px;
  margin-top: 2px;
}

.main strong {
  font-size: 14px;
}

.main .header {
  display: flex;
}

.main .header .today-header-right {
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 20px;
}

.main .header .today-header-right h1 {
  font-size: 30px;
  margin-top: 16px;
  margin-bottom: 0px;
}

.main .transactions {
  padding-top: 16px;
  margin: 16px;
  border-top: 1px solid #091021;
}

.main .transactions .transaction-data p {
  font-size: 10px;
}

.main .footer {
  margin-bottom: 2px;
  text-align: right;
}
