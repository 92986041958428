  /*  */

.main {
  width: 100%;
  height: 100%;
  margin-top: 0px;
}

.main h3 {
  font-size: 16px;
}

.main p {
  font-size: 13px;
  margin-top: 2px;
}

.main strong {
  font-size: 14px;
}

.main .header {
  display: flex;
}

.main .header .header-right {
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 180px;
}

.main .header .header-right h1 {
  font-size: 36px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.main .client-data h3 {
  font-size: 20px;
}

.main .client-data {
  border-top: 1px solid #091021;
  margin-top: 8px;
  padding-top: 8px;
}

.main .client-data .data1 {
  display: grid;
  grid-template-columns: repeat(3, 250px);
  list-style: none;
  grid-column-gap: 8px;
  grid-row-gap: 8px;

  margin-top: 4px;
}

.main .product-data {
  border-top: 1px solid #091021;
  margin-top: 8px;
  padding-top: 8px;
}

.main .product-data .data2 {
  display: grid;
  grid-template-columns: 350px 200px 200px;
  list-style: none;
  grid-column-gap: 8px;
  grid-row-gap: 8px;

  margin-top: 4px;
}

.main .product-data .data2 .product p {
  font-size: 16px;
}

.main .fix-data {
  border-top: 1px solid #091021;
  border-bottom: 1px solid #091021;
  margin-bottom: 8px;
  margin-top: 8px;
  padding-bottom: 8px;
  padding-top: 8px;
}

.main .fix-data .data3 {
  display: grid;
  grid-template-columns: repeat(3, 250px);
  list-style: none;
  grid-column-gap: 8px;
  grid-row-gap: 8px;

  margin-top: 4px;
}

.main .fix-data .data3 .final p {
  font-size: 14px;
  font-weight: 700;
}

.main .external-data {
  border-top: 1px solid #091021;
  border-bottom: 1px solid #091021;
  margin-bottom: 8px;
  margin-top: 8px;
  padding-bottom: 8px;
  padding-top: 8px;
}

.main .external-data .data3 {
  display: grid;
  grid-template-columns: repeat(2, 375px);
  list-style: none;
  grid-column-gap: 8px;
  grid-row-gap: 8px;

  margin-top: 4px;

}

.main .external-data .data3 p {
  font-size: 16px;
  font-weight: 700;
}

.main .ticket-area {
  display: grid;
  grid-template-columns: repeat(2, 380px);
  list-style: none;
  grid-column-gap: 1px;
  grid-row-gap: 1px;

  justify-content: center;
  align-items: center;
  text-align: center;

  margin-top: 16px;
}

.main .ticket-area .ticket {
  border: 1px solid #091021;
  justify-content: center;
  align-items: center;
  text-align: center;

  padding: 4px 0;
}

.main .footer {
  text-align: right;
  margin-bottom: 0;
}

.main .footer p {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 10px;
}













