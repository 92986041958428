  /*  */

.main {
  width: 100%;
  height: 100%;
}

.main h3 {
  font-size: 16px;
}

.main p {
  font-size: 13px;
  margin-top: 2px;
}

.main strong {
  font-size: 14px;
}

.main .header {
  display: flex;
}

.main .header .header-right {
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 180px;
}

.main .header .header-right h1 {
  font-size: 36px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.main .client-data {
  border-top: 1px solid #091021;
  margin-top: 8px;
  padding-top: 8px;
}

.main .client-data .data1 {
  display: grid;
  grid-template-columns: repeat(3, 250px);
  list-style: none;
  grid-column-gap: 8px;
  grid-row-gap: 8px;

  margin-top: 4px;
}

.main .order-data {
  border-top: 1px solid #091021;
  margin-top: 8px;
  padding-top: 8px;
}

.main .data-value {
  display: grid;
  grid-template-columns: repeat(2, 250px);
  list-style: none;
  grid-column-gap: 8px;
  grid-row-gap: 8px;

  margin-top: 4px;
}

.main .data-value .value p {
  font-size: 16px;
}

.main .footer {
  text-align: right;
}

.main .footer p {
  margin-top: 0;
  font-size: 10px;
}

.main .destinatario {
  border: 2px solid #091021;
  border-radius: 10px;
  padding: 24px;
}

.main .remetente {
  border-radius: 10px;
  border: 2px solid #091021;
  padding: 24px;
}

.main .destinatario h3 {
  font-size: 25px;
}

.main .remetente h3 {
  font-size: 25px;
}

.main .destinatario .data1 h3 {
  font-size: 30px;
}

.main .destinatario .data1 p {
  font-size: 20px;
}

.main .remetente .data1 h3 {
  font-size: 30px;
}

.main .remetente .data1 p {
  font-size: 20px;
}













